.RedirectContact_Container{
    display: flex;
    justify-content: space-between;
    padding: 30px 50px;
    align-items: center;
    height: 100%;
    background-color: rgba(17, 113, 97, 0.9);
    text-transform: uppercase;
}
.RedirectContact_TextContainer p{
    width: 100%;
    flex-wrap: wrap;
    font-size: 18px;
    font-weight: 500;
}
.RedirectContact_TextContainer h2{
    font-size: 40px;
}
.RedirectContact_Button{
    color: #000;
    border: solid rgba(0, 0, 0, .9);
    font-size: 20px;
    padding: 12px 30px;
    margin-right: 100px;
    font-weight: 700;
}
.RedirectContact_Button:hover{
    padding: 13px 31px;
    background-color: rgba(0, 0, 0, .2);
}

.RedirectContact_Black{
    background-color: #000;
    color: #fff;
}
.RedirectContact_Black .RedirectContact_Button{
    color: #fff;
    border: solid rgba(17, 113, 97, 0.9);
}
@media screen and (max-width: 1450px){
    .RedirectContact_TextContainer{
        width: 60%;
    }
    .RedirectContact_TextContainer p{
        width: 100%;
    }
    .RedirectContact_TextContainer h2{
        width: 100%;
    }
    .RedirectContact_Container{
        padding: 30px 10px;
    }
    .RedirectContact_Button{
        margin-right: 50px;
    }
}
@media screen and (max-width: 720px){
    .RedirectContact_TextContainer p{
        font-size: 10px;
        width: 90%;
    }
    .RedirectContact_TextContainer h2{
        font-size: 25px;
        width: 100%;
    }
    .RedirectContact_Container{
        flex-direction: column;
        justify-content: center;
        text-align: center;
        height: 100%;
        padding: 30px 0;
    }
    .RedirectContact_TextContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .RedirectContact_Buttoncontainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    .RedirectContact_Button{
        margin: 0;
    }
}