.Reel_Container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Reel_Reel{
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 4s ease;
}
.Reel_ImgContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 4s ease;
}
.Reel_ImgContainer img{
    width: 80%;
    height: 80vh;
    object-fit: contain;
    object-position: center;
    transition: all 0.5s ease;
    border-radius: 5px;
}
.Reel_Button{
    color: rgba(17, 113, 97, 1);
    font-size: 50px;
    background-color: transparent;
    border: none;
    padding: 50px 0;
}
.Reel_Button:hover{
    cursor: pointer;
}

.Reel_Index{
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    width: 100%;
}

.Reel_Index p{
    font-size: 18px;
    padding: 3px 10px;
    margin: 0 10px;
    border: 2px solid rgba(17, 113, 97, 1);
    color: rgba(17, 113, 97, 1);
    border-radius: 20%;
}
.Reel_Index p:nth-child(2),
.Reel_Index p:nth-child(4){
    font-size: 10px;
    padding: 5px 10px;
}

.Reel_Index p:nth-child(1),
.Reel_Index p:nth-child(5){
    font-size: 8px;
    padding: 5px 0;
    margin: 0;
    border: none;
}

.Reel_None{
    opacity: 0;
}
.Reel_ButtonBlack{
    color: rgb(7, 31, 27);
}
.Reel_ButtonBlack:hover{
    cursor: default;
}

@media screen and (max-width: 720px){
    .Reel_ImgContainer img{
        width: 100%;
        height: 350px;
    }
}