.Welcome_Container{
    display: flex;
    height: 400px;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
}
.Welcome_ImgContainer{
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../../../assets/pic_gallery/IMG-20230524-WA0064.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    clip-path: polygon(0 0, 50% 0, 100% 100%, 0% 100%);
    height: 100%;
    width: 100%;
    flex: 1;
}
.Welcome_TextContainer{
    padding-right: 20px;
}
.Welcome_TextContainer{
    flex: 2;
}
.Welcome_TextContainer h2{
    font-size: 40px;
    margin-bottom: 20px;
    text-transform: uppercase;
}

@media screen and (max-width: 720px){
    .Welcome_Container{
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .Welcome_TextContainer h2{
        font-size: 20px;
        margin-bottom: 10px;
    }
    .Welcome_TextContainer p{
        font-size: 12px;
        padding-right: 0;
    }
    .Welcome_TextContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
    }
    .Welcome_ImgContainer{
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }

}