.Copyright_Container{
    background-color: rgba(0, 0, 0, 0.8);
    color: #aaa;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
}
.Copyright_Container p{
    font-size: 12px;
}
.Copyright_Container p a{
    color: #aaa;
    font-size: 13px;
    font-weight: 650;
}
.Copyright_Social{
    margin-top: 10px
}

.Copyright_Icon{
    font-size: 18px;
    padding: 0 8px;
    color: #aaa;
}

@media screen and (max-width: 720px){
    .Copyright_Container{
        padding: 10px 30px;
        text-align: center;
    }
}