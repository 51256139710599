.River_1Port_Container{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../../../assets/lodges/sanhumbberto/living.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-transform: uppercase;
}

.River_1Port_Container h2{
    font-size: 100px;
}
.River_1Port_Line{
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, .5);
    margin-top: -18px;
}
.River_1Port_Container p{
    font-size: 30px;
    margin-top: 10px;
}

@media screen and (max-width: 790px){
    .River_1Port_Container h2{
        font-size: 50px;
    }
    .River_1Port_Container p{
        font-size: 15px;
    }
    .River_1Port_Line{
        margin-top: -5px;
    }
}