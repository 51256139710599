.Form_Container{
    padding-top: 80px;
    background-color: rgba(0, 0, 0, .95);
    color: #fff;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    justify-content: center;
}
.Form_Form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Form_Text{
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    width: 30vw;
}
.Form_Text h2{
    font-size: 30px;
}
.Form_Text div{
    letter-spacing: 8px;
    color: #1a6859;
    margin-top: 15px;
    margin-bottom: 20px;
}
.Form_Text p{
    font-size: 12px;
}
.Form_Form label{
    font-size: 20px;
    margin-bottom: 10px;
}
.Form_Form input{
    background-color: transparent;
    border-radius: 20px;
    border: solid #1a6859 1px;
    width: 30vw;
    height: 30px;
    margin-bottom: 30px;
    color: #fff;
    padding: 10px;
}
.Form_Form textarea{
    width: 30vw;
    height: 100px;
    border: solid #1a6859 1px;
    background-color: transparent;
    border-radius: 5px;
    resize: none;
    color: #fff;
    padding: 10px;
}
.Form_Form button{
    background: #1a6859;
    margin: 30px 0;
    border-radius: 20px;
    padding: 5px 10px;
    color: #fff;
    font-size: 20px;
    border: none;
    text-transform: uppercase;
    font-weight: 600;
}
.Form_DivContent{
    display: flex;
    flex-direction: column;
}
.Form_ImgContainer{
    transition: all 1s ease;
    width: 100%;
    height: 700px;
    animation: animation 20s infinite;
    transition: all 2s ease;
    background-image: url('../../../assets/lodges/collon_cura/living.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    animation: cambiarFondoFormulario 20s infinite;
}
.Form_ImgContainer img{
    width: 100%;
    height: 100%;
    margin-bottom: -4px;
}
.From_ImgMobile{
    display: none;
    margin-top: -45px;
    margin-bottom: 20px;
}
.From_ImgMobile img{
    width: 100%;
    height: 150px;
    object-fit: cover;
    transition: all 2s ease;
}

@keyframes cambiarFondoFormulario {
    0% {
      background-image:  url('../../../assets/pic_gallery/IMG-20230524-WA0067.jpg');
    }
    33% {
      background-image:  url('../../../assets/pic_gallery/IMG-20230524-WA0065.jpg');
    }
    66% {
      background-image:  url('../../../assets/pic_gallery/IMG-20230524-WA0064.jpg');
    }
    100% {
      background-image:  url('../../../assets/pic_gallery/IMG-20230524-WA0063.jpg');
    }
}

@media screen and (max-width: 720px){
    .Form_ImgContainer{
        display: none;
    }
    .Form_Text{
        width: 40vh;
    }
    .Form_Form input{
        width: 40vh;
    }
    .Form_Form textarea{
        width: 40vh;
    }
    .Form_Form{
        padding: 50px 0;
    }
    .From_ImgMobile{
        display: grid;
        grid-template-columns: repeat(2,50%);
    }
}