.Photo_Container{
    background-color: rgba(17, 113, 97, 0.9);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}
.Photo_Container h2{
    font-size: 60px;
    text-transform: uppercase;
    margin-top: 0;
}
.Photo_ImgContainer{
    display: grid;
    grid-template-columns: repeat(3,30%);
    justify-content: center;
    width: 100%;
}
.Photo_ImgCard{
    position: relative;
    width: 100%;
    height: 400px;
    transition: all .3s ease;
}
.Photo_ImgCard i{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    font-size: 100px;
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: all 0.6s ease;
}
.Photo_Img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.Photo_ImgCard:hover{
    filter: brightness(50%);
    transform: scale(1.03);
    cursor: pointer;
    z-index: 100;
}
.Photo_ImgCard:hover i{
    visibility: visible;
    opacity: 1;
}
.Photo_Line{
    width: 15%;
    height: 1px;
    background-color: #000;
    margin-bottom: 30px;
}

.black{
    background-color: #000;
    color: #fff;
}
.black .Photo_Line{
    background-color: #fff;
}

@media screen and (max-width: 720px){
    .Photo_ImgContainer{
        grid-template-columns: repeat(2,45%);
    }
    .Photo_ImgCard:nth-child(4) ~ .Photo_ImgCard{
        visibility: hidden;
        opacity: 0;
        display: none;
    }
    .Photo_ImgCard{
    height: 200px;
    }
}