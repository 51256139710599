.Footer_Container{
    background-color: rgba(17, 113, 97, .8);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
}
.Footer_ImgContainer{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Footer_ImgContainer img{
    height: 200px;
}

.Footer_TexContainer{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: start;
}
.Footer_TexContainer h2{
    font-size: 30px;
    text-transform: uppercase;
}
.Footer_TexContainer p{
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 5px;
    padding-left: 15px;
    font-weight: 600;
}
.Footer_Mail{
    text-decoration: none;
    color: #000;
}
.Footer_Mail:hover{
    color: rgb(117, 163, 156);
}
.Footer_Social{
    margin-top: 20px;
}
.Footer_Social i{
    color: #000;
    font-size: 20px;
}

.Footer_Social i:hover{
    transform: scale(1.1);
}

@media screen and (max-width: 720px){
    .Footer_Container{
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }
    .Footer_TexContainer{
        width: 100%;
        align-items: center;
    }
    .Footer_TexContainer h2{
        margin-top: 20px;
    }
    .Footer_ImgContainer img{
        height: 100px;
    }
    .Footer_TexContainer h2{
        font-size: 30px;
    }
}