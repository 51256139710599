.River_4Port_Container{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../../../../assets/lodges/tipileuken/outside_4.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-transform: uppercase;
}

.River_4Port_Container h2{
    font-size: 100px;
}
.River_4Port_Line{
    width: 100%;
    height: 1px;
    background-color: #fff;
    margin-top: -18px;
}
.River_4Port_Container p{
    font-size: 20px;
    margin-top: 10px;
}

@media screen and (max-width: 720px){
    .River_4Port_Container h2{
        font-size: 50px;
    }
    .River_4Port_Container p{
        font-size: 10px;
    }
    .River_4Port_Line{
        margin-top: -5px;
    }
}