@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto+Mono:ital,wght@0,100;0,300;1,700&family=Roboto:ital,wght@0,300;0,400;1,900&display=swap');


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
}
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./assets/pic_gallery/IMG-20230524-WA0067.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
body::-webkit-scrollbar {
  background-color: #1a6859;
  width: 8px;
}

body::-webkit-scrollbar-thumb {
  background: #0c2c26;
  border-radius: 2rem;
}

body::-webkit-scrollbar-thumb:hover {
  background: #071815;
}
