.River_XPort_Container{
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../../../../assets/Rivers/malleo/malleo_4.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-transform: uppercase;
}

.River_XPort_Container h2{
    font-size: 70px;
}
.River_XPort_Line{
    width: 100%;
    height: 1px;
    background-color: #fff;
    margin-bottom: -10px;
}
.River_XPort_Container p{
    font-size: 16px;
    margin-bottom: 6px;
}

@media screen and (max-width: 720px){
    .River_XPort_Container h2{
        font-size: 50px;
    }
    .River_XPort_Container p{
        font-size: 10px;
    }
    .River_XPort_Line{
        margin-top: -5px;
    }
}