.ListLodge_Container{
    display: flex;
    flex-direction: column;
    background-color: rgba(17, 113, 97, 0.9);
    justify-content: center;
    align-items: center;
}

.ListLodge_TitleContainer{
    background-color: #000;
    width: 90%;
    padding: 20px 40px;
    color: #fff;
    text-transform: uppercase;
    font-size: 15px;
}

.ListLodge_ListContainer{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2,30%);
    justify-content:space-evenly;
    align-items: center;
    padding: 40px 0;
}
.ListLodge_TitleContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #000;
    color: #fff;
    padding-bottom: 15px;
    text-transform: uppercase;
    margin: 20px 0;
    position: relative;
}
.ListLodge_Line{
    width: 50%;
    height: 1px;
    background-color: #fff;
    margin: 2px 0 10px 0;
}
.ListLodge_TitleContent h2{
    margin-top: 10px;
    font-size: 30px;
}
.ListLodge_TitleContent p{
    font-size: 12px;
}
.ListLodge_TitleContent img{
    width: 100%;
    height: 300px;
}
.ListLodge_Hover{
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(17, 113, 97, 0.4);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: all .5s ease;
}
.ListLodge_Hover Link{
    width: 100%;
    height: 100%;
}
.ListLodge_Hover i{
    color: #fff;
    font-size: 80px;
    cursor: pointer;
}

.ListLodge_TitleContent:hover .ListLodge_Hover{
    opacity: 1;
    visibility: visible;
    cursor: pointer;
}

@media screen and (max-width: 720px){
    .ListLodge_ListContainer{
        grid-template-columns: repeat(1,80%);
    }
}