.DescriptionLodge_Container{
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    margin-top: 70px;
    padding: 4% 10% 5% 10%;
    color: #fff;
    text-transform: uppercase;
}
.DescriptionLodge_Container h2{
    font-size: 50px;
    margin-bottom: 20px;
}
.DescriptionLodge_Container p{
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 1px;
    font-weight: 500;
}
.DescriptionLodge_Container h3{
    font-size: 25px;
    margin: 30px 0 20px 0;
}
.DescriptionLodge_Line{
    margin-bottom: 50px;
    letter-spacing: 10px;
    font-weight: 800;
    color: #1b7161;
}