/* Section Container */
.River_1Description_Container{
    display: flex;
    background-color: #000;
    color: #fff;
    padding: 40px 40px;
    justify-content: center;
    align-items: center;
    position: relative;
    text-transform: uppercase;
}


/* Section One */
.River_1Description_SectionOne{
    flex: 2;
    padding: 50px;
}
.River_1Description_SectionOne h2{
    font-size: 50px;
    margin-bottom: 20px;
}
.River_1Description_SectionOne p{
    font-size: 13px;
    margin: 30px 0;
    font-weight: 550;
}
.River_1Description_Bars{
    color: #1b7161;
    letter-spacing: 8px;
    font-size: 12px;
}

.River_1Description_LineH{
    width: 20%;
    height: 1px;
    background-color: #fff;
    margin: 5px 0;
}
.River_1Description_ServicesIcon{
    display: grid;
    grid-template-columns: repeat(4,20%);
    justify-content: space-evenly;
}
.River_1Description_ServicesIcon div{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin:20px;
    
}
.River_1Description_ServicesIcon div img{
    width: 50px;
    height: 50px;
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 50%;
    border: 2px solid #000;
    filter: invert(100%);
    object-fit: cover;
}

/*Line Separation*/
.River_1Description_LineV{
    width: 2px;
    height: 800px;
    background-color: #fff;
}

/* Section Two */
.River_1Description_SectionTwo{
    flex: 1;
    padding: 50px;
    border-left: 2px solid #fff;
    height: 100%;
}
.River_1Description_SectionTwo h2{
    font-size: 30px;
    margin-bottom: 20px;
}
.River_1Description_SectionTwo_Section h3{
    font-size: 20px;
    margin: 20px 0;
    border-bottom: 1px solid #fff;
    padding-bottom: 10px;
    width: 50%;
}
.River_1Description_Programs{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.River_1Description_Programs div{
    display: flex;
    align-items: center;
    margin: 10px;
}
.River_1Description_Programs div img{
    margin-right: 15px;
    padding: 5px;
    width: 50px;
    height: 50px;
    filter: invert(100%);
    border: 2px solid #000;
    border-radius: 50%;
}

@media screen and (max-width: 780px){
    .River_1Description_SectionTwo{
        visibility: hidden;
        opacity: 0;
        display: none;
    }
    .River_1Description_Container{
        flex-direction: column;
        padding: 10px 0;
    }
    .River_1Description_SectionOne{
        padding: 10px;
    }
    .River_1Description_SectionOne h2{
        font-size: 30px;
    }
    .River_1Description_ServicesIcon div img{
        width: 40px;
        height: 40px;
        padding: 5px;
    }
    .River_1Description_ServicesIcon div{
        margin-top: 15px;
    }
}
