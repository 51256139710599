.Nav_Container{
    width: 100%;
    height: 80px;
    background-color: #1b7161;
    opacity: .9;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
}
.Nav_ContainerLogo{
    display: flex;
    align-items: center;
    height: 100%;
}
.Nav_Logo{
    height: 70px;
    margin-top: 5px;
    margin-left: 60px;
}
.Nav_Logo:hover{
    transform: scale(1.1);
    cursor: pointer;
    filter: invert(10%);
}
.Nav_Menu{
    max-width: 1200px;
    height: 100%;
}
.Nav_Nav{
    height: 100%;
}

.Nav_ContentPrimary{
    height: 100%;
    display: flex;

}
.Nav_Li{
    height: 100%;
    list-style: none;
    position: relative;
}
.Nav_LinkPrimary{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 25px;
    text-transform: uppercase;
    font-size: 14px;
    transition: all 100ms ease;
    color: #fff;
}
.Nav_LinkPrimary:hover{
    transform: scale(1.1);
    background-color: #1a6859;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}

#selected{
    transform: scale(1.1);
    background-color: #1a6859;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}

.Nav_Submenu{
    width: 200px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    position: absolute;
    top: 90px;
    left: -5px;
    padding: 14px 0px;
    visibility: hidden;
    opacity: 0;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    z-index: 100;
    transition: all 300ms ease;
}

.Nav_Li:hover .Nav_Submenu{
    visibility: visible;
    opacity: 1;
    top: 60px;
}

.Nav_Submenu::before{
    content: '';
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #fff;
    position: absolute;
    top: -12px;
    left: 20px;
}

.Nav_LinkSecundary{
    display: block;
    color: #1a6859;
    padding: 6px;
    padding-left: 14px;
    margin-top: 10px;
    font-size: 14px;
    text-transform: uppercase;
    transition: all 300ms ease;
}
.Nav_LinkSecundary:hover{
    background: #1b7161;
    color: #fff;
    transform: scale(1.1);
    padding-left: 30px;
    font-size: 14px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}


.Nav_IconMenu{
    font-size: 30px;
    color: #fff;
    cursor: pointer;
    width: 30px;
    height: 100%;
    align-items: center;
    display: none;
}
.Nav_SubtitleSection{
    font-size: 10px;
    text-transform: uppercase;
    margin-left: 5px;
    width: 100%;
    color: #bbb;
    text-decoration: underline;
    user-select: none;
    margin-top: 15px;
}
.Nav_Select{
    color: #2fbea2;
}

@media screen and (max-width: 720px){
    .Nav_Container{
        padding: 10px;
    }
    .Nav_Logo{
        margin-left: 15px;
    }
    .Nav_ContentPrimary{
        height: 0px;
        opacity: 0;
        visibility: hidden;
    }
    .Nav_LinkPrimary:hover{
        transform: scale(1);
    }
    .Nav_Submenu{
        left: 0px;
    }
    .Nav_Li:hover .Nav_Submenu{
        top: 60px;
        width: 100%;
    }
    
    #selected{
        transform: scale(1);
    }

    .Nav_IconMenu{
        display: flex;
    }

    .Nav_ContentPrimary{
        flex-direction: column;
        background-color: #1a6859;
        position: fixed;
        left: 0;
        top: 65px;
        width: 100%;
        height: 350px;
        transition: all 300ms ease;
        z-index: 100;
        opacity: 1;
        visibility: visible;
    }

    .Nav_Inactive{
        height: 0px;
        opacity: 0;
        visibility: hidden;
    }
}