.error_container{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
}

.error_container h2{
    font-size: 10vw;
    color: #1b7161;
}

.error_container p{
    font-size: 2vw;
}
