.Description_Container{
    display: flex;
    height: 400px;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
}
.Description_ImgContainer{
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../../../assets/pic_gallery/IMG-20230524-WA0063.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
    height: 100%;
    width: 100%;
    flex: 1;
}
.Description_TextContainer{
    flex: 2;
}
.Description_TextContainer h2{
    font-size: 40px;
    margin-left: 50px;
    text-transform: uppercase;
}
.Description_TextContainer p{
    margin-left: 50px;
}

@media screen and (max-width: 720px){
    .Description_Container{
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .Description_TextContainer h2{
        font-size: 20px;
        margin-bottom: 10px;
        margin-left: 0px;
    }
    .Description_TextContainer p{
        font-size: 12px;
        margin-left: 0px;
    }
    .Description_TextContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
    }
    .Description_ImgContainer{
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}