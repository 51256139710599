.ModalPhoto_Container{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ModalPhoto_Close{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 4s ease;
}
.ModalPhoto_Container img{
    width: 60%;
    height: 60%;
    object-fit: contain;
    object-position: center;
    transition: all 0.5s ease;
}
.ModalPhoto_Container button{
    background-color: transparent;
    color: #fff;
    font-size: 50px;
    border: none;
    border-radius: 1px;
    transition: all .5s ease;
}
.ModalPhoto_ButtonPrev{
    padding: 40px 30px 40px 10px;
}
.ModalPhoto_ButtonNext{
    padding: 40px 10px 40px 30px;
}
.ModalPhoto_Container button:hover{
    color: rgb(17, 113, 97);
    cursor: pointer;
}

@media screen and (max-width: 720px){
    .ModalPhoto_Container{
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.8);
    }
    .ModalPhoto_Container img{
        width: 100%;
        height: 30%;
    }
    .ModalPhoto_Container button{
        font-size: 30px;
        height: 30%;
    }
    .ModalPhoto_ButtonPrev{
        padding: 5px 5px 5px 3px;
    }
    .ModalPhoto_ButtonNext{
        padding: 5px 3px 5px 5px;
    }
}